const PrivacyPolicyPage = () => {
  return (
    <div className="legal-page">
      <h1>My Nota Privacy Policy</h1>
      <p>Last modified: December 16, 2024</p>
      <p>
        NOTA (<strong>“Company”</strong> or <strong>“We”</strong>) respects your
        privacy and is committed to protecting it through our compliance with
        this policy. This policy describes:
      </p>
      <ul>
        <li>
          The types of information we may collect or that you may provide when
          you access or use My Nota (the <strong>“App”</strong>).
        </li>
        <li>
          Our practices for collecting, using, maintaining, protecting, and
          disclosing that information.
        </li>
      </ul>
      <p>
        This policy applies only to information we collect in this App and in
        email, text, and other electronic communications sent through or in
        connection with this App.
      </p>
      <p>This policy DOES NOT apply to information that:</p>
      <ul>
        <li>
          We collect offline or on any other Company apps or websites, including
          websites you may access through this App.
        </li>
        <li>You provide to or is collected by any third party.</li>
      </ul>
      <p>
        Our websites and apps, and these other third parties may have their own
        privacy policies, which we encourage you to read before providing
        information on or through them.
      </p>
      <p>
        Please read this policy carefully to understand our policies and
        practices regarding your information and how we will treat it. If you do
        not agree with our policies and practices, do not download, register
        with, or use this App. By downloading, registering with, or using this
        App, you agree to this privacy policy. This policy may change from time
        to time (see Changes to Our Privacy Policy). Your continued use of this
        App after we revise this policy means you accept those changes, so
        please check the policy periodically for updates.
      </p>
      <h2>Children Under the Age of 13</h2>
      <p>
        The App is not intended for children under [13] years of age, and we do
        not knowingly collect personal information from children under 13. If we
        learn we have collected or received personal information from a child
        under 13 without verification of parental consent, we will delete that
        information. If you believe we might have any information from or about
        a child under 13, please contact us at{" "}
        <a href="mailto:info@mynota.io">info@mynota.io</a>.
      </p>
      <p>
        California residents under 16 years of age may have additional rights
        regarding the collection and sale of their personal information. Please
        see Your State Privacy Rights for more information.
      </p>
      <h2>Information We Collect and How We Collect It</h2>
      <p>We collect information from and about users of our App:</p>
      <ul>
        <li>Directly from you when you provide it to us.</li>
        <li>Automatically when you use the App. </li>
      </ul>
      <h3>
        <em>Information You Provide to Us</em>
      </h3>
      <p>
        When you download, register with, or use this App, we may ask you
        provide information:
      </p>
      <ul>
        <li>
          By which you may be personally identified, such as name, postal
          address, email address, telephone number, or any other identifier by
          which you may be contacted online or offline (
          <strong>“personal information”</strong>).
        </li>
        <li>That is about you but individually does not identify you.</li>
      </ul>
      <p>This information includes: </p>
      <ul>
        <li>
          Information that you provide by filling in forms in the App. This
          includes information provided at the time of registering to use the
          App and requesting further services. We may also ask you for
          information when you report a problem with the App.
        </li>
        <li>
          Records and copies of your correspondence (including email addresses
          and phone numbers), if you contact us.
        </li>
        <li>
          Your responses to surveys that we might ask you to complete for
          research purposes.
        </li>
        <li>
          Details of transactions you carry out through the App and of the
          fulfillment of your orders. You may be required to provide financial
          information before placing an order through the App.
        </li>
      </ul>
      <p>
        You may also provide information for publication or display (
        <strong>“Posted”</strong>) on public areas of the app (collectively,{" "}
        <strong>“User Contributions”</strong>). Your User Contributions are
        Posted and transmitted to others at your own risk. Please be aware that
        no security measures are perfect or impenetrable. We cannot control the
        actions of third parties with whom you may choose to share your User
        Contributions. Therefore, we cannot and do not guarantee that your User
        Contributions will not be viewed by unauthorized persons.
      </p>
      <h3>
        <em>
          Information We Collect Through Automatic Data Collection Technologies
        </em>
      </h3>
      <p>
        When you download, access, and use the App, it may use technology to
        automatically collect:
      </p>
      <ul>
        <li>
          <strong>Usage Details.</strong> When you access and use the App, we
          may automatically collect certain details of your access to and use of
          the App, including traffic data, location data, logs, and other
          communication data and the resources that you access and use on or
          through the App.
        </li>
        <li>
          <strong>Device Information.</strong> We may collect information about
          your mobile device and internet connection, including the device's
          unique device identifier, IP address, operating system, browser type,
          mobile network information, and the device's telephone number.
        </li>
        <li>
          <strong>Stored Information and Files.</strong> The App also may access
          metadata and other information associated with other files stored on
          your device. This may include, for example, photographs, audio and
          video clips, personal contacts, and address book information.
        </li>
        <li>
          <strong>Location Information.</strong> This App may collect real-time
          information about the location of your device.{" "}
        </li>
      </ul>
      <p>
        If you do not want us to collect this information do not download the
        App or delete it from your device.
      </p>
      <p>
        The technologies we use for automatic information collection may
        include:
      </p>
      <ul>
        <li>
          <strong>Cookies (or mobile cookies).</strong> A cookie is a small file
          placed on your smartphone. It may be possible to refuse to accept
          mobile cookies by activating the appropriate setting on your
          smartphone. However, if you select this setting you may be unable to
          access certain parts of our App.{" "}
        </li>
        <li>
          <strong>Web Beacons.</strong> Pages of the App and our emails may
          contain small electronic files known as web beacons (also referred to
          as clear gifs, pixel tags, and single-pixel gifs) that permit the
          Company, for example, to count users who have visited those pages or
          opened an email and for other related app statistics (for example,
          recording the popularity of certain app content and verifying system
          and server integrity).
        </li>
      </ul>
      <h3>
        <em>Third-Party Information Collection</em>
      </h3>
      <p>
        When you use the App or its content, certain third parties may use
        automatic information collection technologies to collect information
        about you or your device. These third parties may include:{" "}
      </p>
      <ul>
        <li>[Advertisers, ad networks, and ad servers.]</li>
        <li>Analytics companies.</li>
        <li>Your mobile device manufacturer. Your mobile service provider.</li>
        <li>Your mobile service provider.</li>
      </ul>
      <p>
        These third parties may use tracking technologies to collect information
        about you when you use this app. The information they collect may be
        associated with your personal information or they may collect
        information, including personal information, about your online
        activities over time and across different websites, apps, and other
        online services websites. They may use this information to provide you
        with interest-based (behavioral) advertising or other targeted content.
      </p>
      <p>
        We do not control these third parties' tracking technologies or how they
        may be used. If you have any questions about an advertisement or other
        targeted content, you should contact the responsible provider directly.
      </p>
      <h2>How We Use Your Information</h2>
      <p>
        We use information that we collect about you or that you provide to us,
        including any personal information, to:
      </p>
      <ul>
        <li>
          Provide you with the App and its contents, and any other information,
          products, or services that you request from us.
        </li>
        <li>Fulfill any other purpose for which you provide it.</li>
        <li>
          Carry out our obligations and enforce our rights arising from any
          contracts entered into between you and us, including for billing and
          collection.
        </li>
        <li>
          Notify you when App updates are available, and of changes to any
          products or services we offer or provide though it.
        </li>
      </ul>
      <p>
        The usage information we collect helps us to improve our App and to
        deliver a better and more personalized experience by enabling us to:
      </p>
      <ul>
        <li>Estimate our audience size and usage patterns.</li>
        <li>
          Store information about your preferences, allowing us to customize our
          App according to your individual interests.
        </li>
        <li>Speed up your searches.</li>
        <li>Recognize you when you use the App.</li>
      </ul>
      <h2>Disclosure of Your Information</h2>
      <p>
        We may disclose aggregated information about our users, and information
        that does not identify any individual or device, without restriction.
      </p>
      <p>
        In addition, we may disclose personal information that we collect or you
        provide:
      </p>
      <ul>
        <li>To our subsidiaries and affiliates.</li>
        <li>
          To contractors, service providers, and other third parties we use to
          support our business.
        </li>
        <li>
          To a buyer or other successor in the event of a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of NOTA's assets, whether as a going concern or as part
          of bankruptcy, liquidation, or similar proceeding, in which personal
          information held by NOTA about our App users is among the assets
          transferred.
        </li>
        <li>To fulfill the purpose for which you provide it.</li>
        <li>
          For any other purpose disclosed by us when you provide the
          information.
        </li>
        <li>With your consent.</li>
        <li>
          To comply with any court order, law, or legal process, including to
          respond to any government or regulatory request.
        </li>
        <li>
          To enforce our rights arising from any contracts entered into between
          you and us, including the End User License Agreement{" "}
          <a href="./eula">[Link to End User License Agreement]</a>, and for
          billing and collection.
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of NOTA, our customers, or others.
        </li>
      </ul>

      <h2>
        Your Choices About Our Collection, Use, and Disclosure of Your
        Information
      </h2>
      <p>
        We strive to provide you with choices regarding the personal information
        you provide to us. This section describes mechanisms we provide for you
        to control certain uses and disclosures of over your information.
      </p>
      <ul>
        <li>
          <strong>Tracking Technologies.</strong> You can set your browser to
          refuse all or some browser cookies, or to alert you when cookies are
          being sent. If you disable or refuse cookies or block the use of other
          tracking technologies, some parts of the App may then be inaccessible
          or not function properly.
        </li>
        <li>
          <strong>Location Information.</strong> You can choose whether or not
          to allow the App to collect and use real-time information about your
          device's location through the device's privacy settings. If you block
          the use of location information, some parts of the App may become
          inaccessible or not function properly.
        </li>
      </ul>
      <p>
        We do not control third parties' collection or use of your information
        to serve interest-based advertising. However, these third parties may
        provide you with ways to choose not to have your information collected
        or used in this way. You can opt out of receiving targeted ads from
        members of the Network Advertising Initiative (“NAI”) on the NAI's
        website.
      </p>
      <h3>Accessing and Correcting Your Personal Information</h3>
      <p>
        You can review and change your personal information by logging into the
        App and visiting your account profile page.
      </p>
      <p>
        You may also send us an email at{" "}
        <a href="mailto:info@mynota.io">info@mynota.io</a> to request access to,
        correct, or delete any personal information that you have provided to
        us. We cannot delete your personal information except by also deleting
        your user account. We may not accommodate a request to change
        information if we believe the change would violate any law or legal
        requirement or cause the information to be incorrect.
      </p>
      <h3>Your State Privacy Rights</h3>
      <p>
        State consumer privacy laws may provide their residents with additional
        rights regarding our use of their personal information. California's
        “Shine the Light” law (Civil Code Section § 1798.83) permits users of
        our App that are California residents to request certain information
        regarding our disclosure of personal information to third parties for
        their direct marketing purposes. To make such a request, please send an
        email to <a href="mailto:info@mynota.io">info@mynota.io</a>.
      </p>
      <h3>Data Security</h3>
      <p>
        We have implemented measures designed to secure your personal
        information from accidental loss and from unauthorized access, use,
        alteration, and disclosure. The safety and security of your information
        also depends on you. Where we have given you (or where you have chosen)
        a password for access to certain parts of our App, you are responsible
        for keeping this password confidential. We ask you not to share your
        password with anyone.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet and
        mobile platforms is not completely secure. Although we do our best to
        protect your personal information, we cannot guarantee the security of
        your personal information transmitted through our App. Any transmission
        of personal information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures we provide.
      </p>
      <h3>Changes to Our Privacy Policy</h3>
      <p>
        We may update our privacy policy from time to time. If we make material
        changes to how we treat our users' personal information, we will notify
        you through a notice on the App home page. The date the privacy policy
        was last revised is identified at the top of the page.
      </p>
      <h3>Contact Information</h3>
      <p>
        To ask questions or comment about this privacy policy and our privacy
        practices, contact us at:{" "}
        <a href="mailto:info@mynota.io">info@mynota.io</a>
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
