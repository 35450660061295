const CommunityGuidelinesPage = () => {
  return (
    <div className="legal-page">
      <h1>Community Guidelines</h1>
      <p>Last modified: December 16, 2024</p>

      <p>
        Welcome to My Nota (the “App”)! Our community is at the heart of
        everything we do, and these guidelines are designed to ensure a safe,
        respectful, and positive experience for everyone. By using our Service,
        you agree to these guidelines, our End User License Agreement and
        Privacy Policy. Violation of these guidelines may result in the
        suspension or termination of your use of the App.
      </p>

      <h2>A. Your Responsibilities</h2>
      <p>You agree to:</p>
      <ol>
        <li>
          <strong>Follow the Terms:</strong> Regularly check for updates and
          comply with these guidelines.
        </li>
        <li>
          <strong>Obey Laws:</strong> Follow all applicable laws, including
          privacy, intellectual property, and anti-spam.
        </li>
        <li>
          <strong>Be Respectful:</strong> Treat all users with respect.
        </li>
      </ol>

      <h2>B. Prohibited Actions</h2>
      <p>
        To maintain a safe and respectful environment, the following actions are
        strictly prohibited:
      </p>
      <ol>
        <li>
          <strong>Age Restrictions</strong>
          <ul>
            <li>Do not use the App if you are under the age of [13].</li>
            <li>
              Do not engage with users who indicate they are under the age of
              [13].
            </li>
          </ul>
        </li>
        <li>
          <strong>Violent Conduct</strong>
          <ul>
            <li>
              Do not use or promote content that encourages violence or
              endangers others’ safety.
            </li>
            <li>
              Prohibited content includes threats of violence, promotion of
              self-harm, or soliciting private personal information.
            </li>
          </ul>
        </li>
        <li>
          <strong>False Representation</strong>
          <ul>
            <li>
              Misrepresenting your identity, age, or affiliations is not
              allowed.
            </li>
            <li>Do not represent yourself untruthfully in any manner.</li>
          </ul>
        </li>
        <li>
          <strong>Service Disruption</strong>
          <ul>
            <li>
              Refrain from any activities that damage or interfere with the
              App’s operation for others, including any spamming and/or hacking
              attempts.
            </li>
          </ul>
        </li>
        <li>
          <strong>Illegal Activities</strong>
          <ul>
            <li>
              Using the App for illegal activities, including money laundering,
              terrorism, hate speech, exchange of obscene or illegal materials,
              or inciting violence, is strictly prohibited.
            </li>
            <li>
              Do not assist or entice others in unlawful transactions or
              conspiracies.
            </li>
            <li>
              Do not send an image or likeness or any other content of a minor
              who not fully clothed or otherwise depicts or implies a minor
              engaged in sexual activity.
            </li>
          </ul>
        </li>
        <li>
          <strong>Harassment and Bullying</strong>
          <ul>
            <li>
              Harassing, bullying, stalking, intimidating, or harming others is
              unacceptable. This includes repeated unwanted messages, derogatory
              remarks, and sharing harmful content aimed at an individual or
              encourages racism, sexism, hatred or bigotry.
            </li>
            <li>
              Respect personal boundaries and consent. Do not coerce anyone into
              sharing personal information or engaging in uncomfortable
              communication.
            </li>
          </ul>
        </li>
        <li>
          <strong>Prohibited Content</strong>
          <ul>
            <li>
              Do not post content that is defamatory, obscene, indecent,
              abusive, or inflammatory, such as hate speech, graphic violence,
              or explicit material.
            </li>
            <li>
              Refrain from sending explicit content or engaging in sexually
              explicit conversations.
            </li>
          </ul>
        </li>
        <li>
          <strong>Unlawful Solicitation</strong>
          <ul>
            <li>
              Soliciting personal information for illegal purposes is
              prohibited.
            </li>
            <li>
              Do not share someone’s personal information without their explicit
              permission.
            </li>
          </ul>
        </li>
        <li>
          <strong>Non-Discrimination</strong>
          <ul>
            <li>
              Engaging in discriminatory behavior or speech based on race,
              ethnicity, nationality, religion, disability, gender, sexual
              orientation, or age is prohibited.
            </li>
            <li>Treat all users with equal respect and dignity.</li>
          </ul>
        </li>
        <li>
          <strong>Financial Solicitation</strong>
          <ul>
            <li>
              Soliciting money or items of value from other users is not
              allowed.
            </li>
            <li>
              Do not promote businesses, services, or commercial ventures
              (including, without limitation, sales, competitions, promotions,
              and advertising, or solicitation for services).
            </li>
          </ul>
        </li>
        <li>
          <strong>Fraud and Schemes</strong>
          <ul>
            <li>
              Engaging in fraud, pyramid schemes, or spreading false or
              misleading information is prohibited.
            </li>
          </ul>
        </li>
        <li>
          <strong>Political Campaigns</strong>
          <ul>
            <li>
              Using the App for political campaign financing or influencing
              elections is not allowed, except for sharing personal political
              opinions.
            </li>
          </ul>
        </li>
        <li>
          <strong>License Violations</strong>
          <ul>
            <li>
              Do not violate the terms of the license granted to you for using
              the App.
            </li>
          </ul>
        </li>
        <li>
          <strong>Unauthorized Disclosure</strong>
          <ul>
            <li>
              Respect privacy and confidentiality. Do not disclose private or
              proprietary information without consent.
            </li>
          </ul>
        </li>
        <li>
          <strong>Intellectual Property Violations</strong>
          <ul>
            <li>
              Do not copy, modify, or distribute our content or intellectual
              property without consent.
            </li>
          </ul>
        </li>
        <li>
          <strong>False Endorsements</strong>
          <ul>
            <li>
              Do not imply that your statements are endorsed by us without
              permission.
            </li>
          </ul>
        </li>
        <li>
          <strong>Encouraging Violations</strong>
          <ul>
            <li>Do not promote activities that violate these guidelines.</li>
          </ul>
        </li>
        <li>
          <strong>False Reports</strong>
          <ul>
            <li>
              Submitting false or misleading reports about other users is
              prohibited.
            </li>
          </ul>
        </li>
        <li>
          <strong>Third Party Communications</strong>
          <ul>
            <li>Do not share or promote external links,</li>
          </ul>
        </li>
        <li>
          <strong>Data Security</strong>
          <ul>
            <li>
              Do not engage in activities that threaten the security of user
              data or the App.
            </li>
          </ul>
        </li>
        <li>
          <strong>Automated Interactions</strong>
          <ul>
            <li>
              Use of bots or automated systems to interact with the App is
              prohibited unless authorized.
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Failure to adhere to these guidelines may result in the suspension or
        termination of your use of the App. Your license to use the App will be
        automatically revoked if you violate any of these rules.
      </p>

      <h3>C. INAPPROPRIATE CONTENT AND MISCONDUCT; REPORTING</h3>
      <strong>
        We do not tolerate inappropriate content or behavior on our App.
      </strong>
      <p>
        We are committed to maintaining a positive and respectful community, and
        we do not tolerate any inappropriate content or misconduct, whether on
        or off of the App (including, but not limited to, on services operated
        by NOTA and our affiliates). We encourage you to report any
        inappropriate Interaction or misconduct by other users. You can report a
        user by contacting support here:{" "}
        <a href="mailto:info@mynota.io">info@mynota.io</a>.
      </p>
      <p>
        As set forth in our Privacy Policy, we may share data between our
        affiliates for the safety and security of our users and may take
        necessary actions if we believe you have violated these guidelines,
        including banning you from our App and/or our affiliates' services.
      </p>
    </div>
  );
};

export default CommunityGuidelinesPage;
