const EULAPage = () => {
  return (
    <div className="legal-page">
      <h1>My Nota End User License Agreement</h1>
      <p>Last modified: December 16, 2024</p>

      <p>
        This End User License Agreement (<strong>“Agreement”</strong>) is a
        binding agreement between you (<strong>“User”</strong> or{" "}
        <strong>“you”</strong>) and NOTA (<strong>“NOTA”</strong>). This
        Agreement governs your use of the My Nota, (including all related
        documentation, the
        <strong>“Application”</strong>). The Application is licensed, not sold,
        to you.
      </p>

      <p>
        BY USING THE APPLICATION, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND
        UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR
        OLDER/OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT
        THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU
        DO NOT AGREE TO THESE TERMS, DO NOT USE THE APPLICATION AND DELETE IT
        FROM YOUR MOBILE DEVICE.
      </p>

      <h2>1. License Grant</h2>
      <p>
        Subject to the terms of this Agreement, NOTA grants you a limited,
        non-exclusive, and nontransferable license to download, install, and use
        the Application for your personal, non-commercial use on a single mobile
        device owned or otherwise controlled by you (
        <strong>“Mobile Device”</strong>) strictly in accordance with the
        Application's documentation.
      </p>

      <h2>2. License Restrictions</h2>
      <p>
        Except as may be expressly permitted by applicable law or expressly
        authorized by the Application, you shall not:
      </p>
      <ul>
        <li>
          (a) copy the Application, except as expressly permitted by this
          license;
        </li>
        <li>
          (b) modify, translate, adapt, or otherwise create derivative works or
          improvements, whether or not patentable, of the Application;
        </li>
        <li>
          (c) reverse engineer, disassemble, decompile, decode, or otherwise
          attempt to derive or gain access to the source code of the Application
          or any part thereof;
        </li>
        <li>
          (d) remove, delete, alter, or obscure any trademarks or any copyright,
          trademark, patent, or other intellectual property or proprietary
          rights notices from the Application, including any copy thereof;
        </li>
        <li>
          (e) rent, lease, lend, sell, sublicense, assign, distribute, publish,
          transfer, or otherwise make available the Application, or any features
          or functionality of the Application, to any third party for any
          reason, including by making the Application available on a network
          where it is capable of being accessed by more than one device at any
          time;
        </li>
        <li>
          (f) use any robot, spider, or other automatic device, process, or
          means to access the Application for any purpose, including monitoring
          or copying any of the material on the Application;
        </li>
        <li>
          (g) use any manual process to monitor or copy any of the material on
          the Application, or for any other purpose not expressly authorized in
          this Agreement, without NOTA's prior written consent;
        </li>
        <li>
          (h) frame, mirror, or otherwise incorporate the Application or any
          portion of the Application as part of any other mobile application,
          website, or service;
        </li>
        <li>
          (i) use the Application in any manner that could disable, overburden,
          damage, or impair the Application or interfere with any other party's
          use of the Application; or
        </li>
        <li>
          (j) remove, disable, circumvent, or otherwise create or implement any
          workaround to any copy protection, rights management, or security
          features in or protecting the Application.
        </li>
      </ul>

      <h3>3. Reservation of Rights</h3>
      <p>
        You acknowledge and agree that the Application is provided under
        license, and not sold, to you. You do not acquire any ownership interest
        in the Application under this Agreement, or any other rights thereto
        other than to use the Application in accordance with the license
        granted, and subject to all terms, conditions, and restrictions, under
        this Agreement. NOTA reserves and shall retain its entire right, title,
        and interest in and to the Application, including all copyrights,
        trademarks, and other intellectual property rights therein or relating
        thereto, except as expressly granted to you in this Agreement.
      </p>

      <h3>4. Collection and Use of Your Information</h3>
      <p>
        You acknowledge that when you download, install, or use the Application,
        NOTA may use automatic means (including, for example, cookies and web
        beacons) to collect information about your Mobile Device and about your
        use of the Application. You also may be required to provide certain
        information about yourself as a condition to downloading, installing, or
        using the Application or certain of its features or functionality. All
        information we collect through or in connection with this Application is
        subject to our Privacy Policy{" "}
        <a href="./privacy-policy">[Link to Privacy Policy]</a>. By downloading,
        installing, using, and providing information to or through this
        Application, you consent to all actions taken by us with respect to your
        information in compliance with the Privacy Policy.
      </p>

      <h3>5. Updates</h3>
      <p>
        NOTA may from time to time in its sole discretion develop and provide
        Application updates, which may include upgrades, bug fixes, patches,
        other error corrections, and/or new features (collectively, including
        related documentation, <strong>“Updates”</strong>). Updates may also
        modify or delete in their entirety certain features and functionality.
        You agree that NOTA has no obligation to provide any Updates or to
        continue to provide or enable any particular features or functionality.
        Based on your Mobile Device settings, when your Mobile Device is
        connected to the internet either:
      </p>
      <ul>
        <li>
          (a) the Application will automatically download and install all
          available Updates; or
        </li>
        <li>
          (b) you may receive notice of or be prompted to download and install
          available Updates.
        </li>
      </ul>
      <p>
        You shall promptly download and install all Updates and acknowledge and
        agree that the Application or portions thereof may not properly operate
        should you fail to do so. You further agree that all Updates will be
        deemed part of the Application and be subject to all terms and
        conditions of this Agreement.
      </p>

      <h3>6. Third-Party Materials</h3>
      <p>
        The Application may display, include, or make available third-party
        content (including data, information, applications, and other products,
        services, and/or materials) or provide links to third-party websites or
        services, including through third-party advertising (
        <strong>“Third-Party Materials”</strong>). You acknowledge and agree
        that NOTA is not responsible for Third-Party Materials, including their
        accuracy, completeness, timeliness, validity, copyright compliance,
        legality, decency, quality, or any other aspect thereof. NOTA does not
        assume and will not have any liability or responsibility to you or any
        other person or entity for any Third-Party Materials. Third-Party
        Materials and links thereto are provided solely as a convenience to you,
        and you access and use them entirely at your own risk and subject to
        such third parties' terms and conditions.
      </p>

      <h3> 7. Term and Termination.</h3>
      <ul>
        <li>
          (a) The term of Agreement commences when you acknowledge your
          acceptance and will continue in effect until terminated by you or NOTA
          as set forth in this Section 7.
        </li>
        <li>
          (b) You may terminate this Agreement by deleting the Application and
          all copies thereof from your Mobile Device.
        </li>
        <li>
          (c) NOTA may terminate this Agreement at any time without notice if it
          ceases to support the Application, which NOTA may do in its sole
          discretion. In addition, this Agreement will terminate immediately and
          automatically without any notice if you violate any of the terms and
          conditions of this Agreement.
        </li>
        <li>
          (d) Upon termination:
          <ul>
            <li>
              (i) all rights granted to you under this Agreement will also
              terminate; and
            </li>
            <li>
              (ii) you must cease all use of the Application and delete all
              copies of the Application from your Mobile Device and account.
            </li>
          </ul>
        </li>
        <li>
          (e) Termination will not limit any of NOTA's rights or remedies at law
          or in equity.
        </li>
      </ul>

      <h3>8. Disclaimer of Warranties</h3>
      <p>
        THE APPLICATION IS PROVIDED TO USER “AS IS” AND WITH ALL FAULTS AND
        DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED
        UNDER APPLICABLE LAW, NOTA, ON ITS OWN BEHALF AND ON BEHALF OF ITS
        AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS,
        EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY,
        OR OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
        AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
        DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT
        LIMITATION TO THE FOREGOING, NOTA PROVIDES NO WARRANTY OR UNDERTAKING,
        AND MAKES NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET
        YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK
        WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE
        WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR
        BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON
        IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS
        OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS
        MAY NOT APPLY TO YOU.
      </p>

      <h3>9. Limitation of Liability</h3>
      <p>
        O THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL NOTA
        OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR
        SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR
        USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT AND SERVICES
        FOR:
      </p>
      <ul>
        <li>
          (a) PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE
          GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS
          INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER
          CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE
          DAMAGES.
        </li>
        <li>
          (b) DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT
          ACTUALLY PAID BY YOU FOR THE APPLICATION.
        </li>
      </ul>
      <p>
        THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF
        BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND
        REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR NOTA WAS ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW
        CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS
        OF LIABILITY MAY NOT APPLY TO YOU.{" "}
      </p>

      <h3>10. Indemnification</h3>
      <p>
        You agree to indemnify, defend, and hold harmless NOTA and its officers,
        directors, employees, agents, affiliates, successors, and assigns from
        and against any and all losses, damages, liabilities, deficiencies,
        claims, actions, judgments, settlements, interest, awards, penalties,
        fines, costs, or expenses of whatever kind, including attorneys' fees,
        arising from or relating to your use or misuse of the Application or
        your breach of this Agreement, including but not limited to the content
        you submit or make available through this Application.
      </p>

      <h3>11. Export Regulation</h3>
      <p>
        The Application may be subject to US export control laws, including the
        Export Control Reform Act and its associated regulations. You shall not,
        directly or indirectly, export, re-export, or release the Application
        to, or make the Application accessible from, any jurisdiction or country
        to which export, re-export, or release is prohibited by law, rule, or
        regulation. You shall comply with all applicable federal laws,
        regulations, and rules, and complete all required undertakings
        (including obtaining any necessary export license or other governmental
        approval), prior to exporting, re-exporting, releasing, or otherwise
        making the Application available outside the US.
      </p>

      <h3>12. Severability</h3>
      <p>
        If any provision of this Agreement is illegal or unenforceable under
        applicable law, the remainder of the provision will be amended to
        achieve as closely as possible the effect of the original term and all
        other provisions of this Agreement will continue in full force and
        effect.
      </p>

      <h3>13. Governing Law</h3>
      <p>
        This Agreement is governed by and construed in accordance with the
        internal laws of the State of Texas without giving effect to any choice
        or conflict of law provision or rule. Any legal suit, action, or
        proceeding arising out of or related to this Agreement or the
        Application shall be instituted exclusively in the federal courts of the
        United States or the courts of the State of Texas. You waive any and all
        objections to the exercise of jurisdiction over you by such courts and
        to venue in such courts.
      </p>

      <h3>14. Entire Agreement</h3>
      <p>
        This Agreement, the Community Guidelines and our Privacy Policy
        constitute the entire agreement between you and NOTA with respect to the
        Application and supersede all prior or contemporaneous understandings
        and agreements, whether written or oral, with respect to the
        Application.{" "}
      </p>

      <h3>15. Waiver</h3>
      <p>
        No failure to exercise, and no delay in exercising, on the part of
        either party, any right or any power hereunder shall operate as a waiver
        thereof, nor shall any single or partial exercise of any right or power
        hereunder preclude further exercise of that or any other right
        hereunder. In the event of a conflict between this Agreement and any
        applicable purchase or other terms, the terms of this Agreement shall
        govern.
      </p>

      <h3>16. Changes to End User License Agreement</h3>
      <p>
        We may revise and update this Agreement from time to time in our sole
        discretion. All changes are effective immediately when we post them and
        apply to all access to and use of the Application thereafter. Your
        continued use of the Application following the posting of revised of
        this Agreement means that you accept and agree to the changes. You are
        expected to check this page from time to time so you are aware of any
        changes, as they are binding on you.
      </p>
    </div>
  );
};

export default EULAPage;
